import React from "react";
import {DemoWrapper,SectionHeadingWrapper,Heading,DemoHref,DemoImgWrapper,DemoImgFigure,
        DemoColHeading,DemoCol,SubHeading} from "./demo.style";
import {Container, Row, Col} from '@bootstrap-styled/v4';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const Demos = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            showcaseJson {
                Demos {
                    DelayConstant
                    Row1{
                        Heading
                        SubHeading
                    }
                    Row2{
                        DemoColHeading
                        DemoColImg{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        url
                    }
                }
            }
        }
    `);
    return (
        <DemoWrapper id="demosContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <SectionHeadingWrapper>
                            <Fade bottom delay={JSONData.showcaseJson.Demos.DelayConstant}>
                                <Heading>
                                {JSONData.showcaseJson.Demos.Row1.Heading}
                                </Heading>
                                <SubHeading>
                                {JSONData.showcaseJson.Demos.Row1.SubHeading}
                                </SubHeading>
                            </Fade>
                        </SectionHeadingWrapper>
                    </Col>
                </Row>

                <Row>
                {
                    JSONData.showcaseJson.Demos.Row2.map((item,idx) => {
                    return <Col md="4" sm="6">
                        <DemoCol>
                            <Fade bottom delay={JSONData.showcaseJson.Demos.DelayConstant}>
                                <DemoImgWrapper>
                                    <DemoHref href={item.url} target="_blank" aria-label={"app-link"+idx}>
                                        <DemoImgFigure>
                                            <GatsImg 
                                                fluid={item.DemoColImg.childImageSharp.fluid} 
                                                className="DemoColImg"
                                                alt=""
                                            />
                                        </DemoImgFigure>
                                    </DemoHref>
                                </DemoImgWrapper>
                            </Fade>
                            <Fade bottom delay={JSONData.showcaseJson.Demos.DelayConstant}>
                                <DemoColHeading>
                                    {item.DemoColHeading}
                                </DemoColHeading>
                            </Fade>
                        </DemoCol>
                    </Col>
                    })
                }
                </Row>
            </Container>
        </DemoWrapper>
    );
}
export default Demos;