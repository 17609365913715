import React from "react";
import {FeaturesWrapper,FeatureCol,FeatureTxtCol,FeatureHeading,FeaturePara,FeatureImgCol,
    RowDirection,UlCustom,LiCustom} from "./features.style";
import {Container, Row, Col} from '@bootstrap-styled/v4';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const Features =()=>{
    const JSONData  = useStaticQuery(graphql`
    query{
        showcaseJson {
            Features {
                DelayConstant
                Row1{
                    FeatureHeading
                    FeaturePara
                    FeatureImg{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    FeaturesList{
                        feature
                    }
                }
                Row2{
                    FeatureHeading
                    FeaturePara
                    FeatureImg{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`);
    return (
        <FeaturesWrapper>
            <Container>
                <FeatureCol>
                    <Row>
                        <Col md="5">
                            <FeatureTxtCol>
                                <Fade bottom delay={JSONData.showcaseJson.Features.DelayConstant}>
                                    <FeatureHeading>
                                    {JSONData.showcaseJson.Features.Row1.FeatureHeading}
                                    </FeatureHeading>
                                    <FeaturePara>
                                    {JSONData.showcaseJson.Features.Row1.FeaturePara}
                                    </FeaturePara>
                                    <UlCustom>
                                        {
                                            JSONData.showcaseJson.Features.Row1.FeaturesList.map((item,idx) => {
                                                return <LiCustom>
                                                    {item.feature}
                                                </LiCustom>
                                            })
                                        }
                                        
                                    </UlCustom>

                                </Fade>
                            </FeatureTxtCol>
                        </Col>
                        <Col md="7">
                            <Fade bottom delay={JSONData.showcaseJson.Features.DelayConstant}>
                                <FeatureImgCol>
                                    <GatsImg 
                                        fluid={JSONData.showcaseJson.Features.Row1.FeatureImg.childImageSharp.fluid} 
                                        className="FeatureImg"
                                        alt=""
                                    />
                                </FeatureImgCol>
                            </Fade>
                        </Col>
                    </Row>
                    <RowDirection>
                        <Col md="5">
                            <FeatureTxtCol>
                                <Fade bottom delay={JSONData.showcaseJson.Features.DelayConstant}>
                                    <FeatureHeading>
                                    {JSONData.showcaseJson.Features.Row2.FeatureHeading}
                                    </FeatureHeading>
                                    <FeaturePara>
                                    {JSONData.showcaseJson.Features.Row2.FeaturePara}
                                    </FeaturePara>
                                </Fade>
                            </FeatureTxtCol>
                        </Col>
                        <Col md="7">
                            <Fade bottom delay={JSONData.showcaseJson.Features.DelayConstant}>
                                <FeatureImgCol>
                                    <GatsImg 
                                        fluid={JSONData.showcaseJson.Features.Row2.FeatureImg.childImageSharp.fluid} 
                                        className="FeatureImg"
                                        alt=""
                                    />
                                </FeatureImgCol>
                            </Fade>
                        </Col>
                    </RowDirection>
                </FeatureCol>
            </Container>
        </FeaturesWrapper>
    );
}
export default Features;