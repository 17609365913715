import React from "react";
import GlobalStyle from "../containers/ShowcasePage/Common/global-styles"
import Banner from "../containers/ShowcasePage/Banner"
import HeaderMenu from "../containers/ShowcasePage/HeaderMenu"
import Demos from "../containers/ShowcasePage/Demos"
import Features from "../containers/ShowcasePage/Features"
import KeyFeatures from "../containers/ShowcasePage/KeyFeatures"
import Download from "../containers/ShowcasePage/Download"
// import Faq from "../containers/ShowcasePage/Faq"
import Footer from "../containers/ShowcasePage/Footer"
import SEO from "../components/seo";
import "../components/layout.css";
import Fonts from "../containers/ShowcasePage/Common/fonts"
import BuyNow from "../components/BuyNow";

const IndexPage = () => (
   <div>
       <Fonts />
       <GlobalStyle />
       <BuyNow />
       <HeaderMenu/>
       <Banner/>
       
       <main>
            <SEO 
                title="ReactJS Templates | GatsbyJS Landing Templates | Zircon"
                description="Buy ReactJS Templates built with GatsbyJS, Bootstrap and Styled-Components"
            />
            <Demos/>
            <Features/>
            <KeyFeatures/>
            {/* <Faq/> */}
            <Download/>
        </main>
       <Footer/>
   </div>

)

export default IndexPage;
