import {createGlobalStyle} from 'styled-components';
const GlobalStyle = createGlobalStyle`

    body{
        font-family: 'Quicksand', sans-serif;
        font-weight:400;
        overflow:hidden;
    }
    h1,h2,h3,h4,h5,h6{
        font-family: 'Quicksand', sans-serif;
        font-weight: 500;
    }
    p{
        font-family: 'Quicksand', sans-serif;
        font-weight:400;
    }
    a{
        font-family: 'Quicksand', sans-serif;
        font-weight: 500;
        font-size:16px;
    }
`;

export default GlobalStyle;