import React from 'react';
import {BannerWrapper,BannerCol,BannerHeading,BannerBtn,
    BannerBtnsWrapper,BannerSubHeading} from './banner.style'
import {Container, Row, Col} from '@bootstrap-styled/v4';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';

const Banner = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            showcaseJson {
                Banner {
                    DelayConstant
                    BannerImage
                    BannerHeading
                    BannerSubHeading
                    BannerBtn
                    BannerBtnHref
                    BannerBtnDemos
                }
            }
        }
    `);
    return (
        <BannerWrapper id="homeContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <BannerCol>
                            <Fade bottom delay={JSONData.showcaseJson.Banner.DelayConstant}>
                                <BannerHeading>{JSONData.showcaseJson.Banner.BannerHeading}</BannerHeading>
                                <BannerSubHeading>{JSONData.showcaseJson.Banner.BannerSubHeading}</BannerSubHeading>
                                <BannerBtnsWrapper>
                                    <BannerBtn rel="noreferrer" target="_blank" href={JSONData.showcaseJson.Banner.BannerBtnHref}>{JSONData.showcaseJson.Banner.BannerBtn}</BannerBtn>
                                    {/* <BannerBtnDemos href="#">{JSONData.showcaseJson.Banner.BannerBtnDemos}</BannerBtnDemos> */}
                                </BannerBtnsWrapper>
                            </Fade>
                        </BannerCol>
                    </Col>
                </Row>
            </Container>
        </BannerWrapper>
    );
}

export default Banner;